import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../Context/AuthProvider";

export default function Sidebar() {
  const { pathname } = useLocation();
  const { user } = useAuth();
  return (
    <aside className="main-sidebar sidebar-dark-dark elevation-4">
      {/* Brand Logo */}
      <Link to="/dashboard/admin" className="brand-link">
        <img
          src="../../../dist/img/dilogo.png"
          alt="JD Aeromatics"
          className="brand-image img-circle elevation-3"
          style={{ opacity: "1" }}
        />
        <span className="brand-text font-weight-900">JD AEROMATICS</span>
      </Link>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="../../../dist/img/user2-160x160.jpg"
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info">
            <Link to="#" className="d-block">
            {user.username}- {user.role}
            </Link>
          </div>
        </div>
        {/* SidebarSearch Form */}
        <div className="form-inline">
          <div className="input-group" data-widget="sidebar-search">
            <input
              className="form-control form-control-sidebar"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <div className="input-group-append">
              <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw" />
              </button>
            </div>
          </div>
        </div>
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}

            <li className="nav-item">
              <Link
                to="/dashboard/admin"
                className={
                  pathname === "/dashboard/admin" ? "nav-link active" : "nav-link"
                }
              >
                <i className="nav-icon fas fa-tachometer-alt" />
                <p>Dashboard</p>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="#" className="nav-link">
                <i class="nav-icon fas fa-edit"></i>
                <p>
                  Master
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/master/groups"
                    className={
                      pathname === "/master/groups"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Groups </p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/master/subgroups"
                    className={
                      pathname === "/master/subgroups"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Sub Groups </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/master/heads"
                    className={
                      pathname === "/master/heads"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Heads </p>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    to="/master/subheads"
                    className={
                      pathname === "/master/subheads"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Sub Heads </p>
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    to="/vendors/newvendor"
                    className={
                      pathname === "/vendors/newvendor"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Sub Heads </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/master/acchead"
                    className={
                      pathname === "/master/acchead"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Account Heads </p>
                  </Link>
                </li>
               
              </ul>
            </li>
            <li className="nav-item">
              <Link to="#" className="nav-link">
                <i class="nav-icon fas fa-store"></i>
                <p>
                  Products
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
              <li className="nav-item">
                  <Link
                    to="/products/category"
                    className={
                      pathname === "/products/category"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Product Category </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/products/subcategory"
                    className={
                      pathname === "/products/subcategory"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Product Sub Category </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/products/newproduct"
                    className={
                      pathname === "/customers/newcustomer"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>New Product </p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="#" className="nav-link">
                <i class="nav-icon fas fa-copy"></i>
                <p>
                  Store
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/purchase/newpurchase"
                    className={
                      pathname === "/purchase/newpurchase"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>New Purchase </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/purchase/stockout"
                    className={
                      pathname === "/purchase/stockout"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Stock Out </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/purchase/inventory"
                    className={
                      pathname === "/purchase/inventory"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Inventory </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/purchase/belowstock"
                    className={
                      pathname === "/purchase/belowstock"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Below Stock </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/purchase/purchasedetails"
                    className={
                      pathname === "/purchase/purchasedetails"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Purchase Details </p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="#" className="nav-link">
                <i class="nav-icon fas fa-copy"></i>
                <p>
                  Vouchers
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/vouchers/payment"
                    className={
                      pathname === "/vouchers/payment"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Payment Voucher </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/vouchers/reciept"
                    className={
                      pathname === "/vouchers/reciept"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Reciept Voucher </p>
                  </Link>
                </li>
              <li className="nav-item">
                  <Link
                    to="/vouchers/journal"
                    className={
                      pathname === "/vouchers/journal"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Journal Voucher </p>
                  </Link>
                </li> 
                <li className="nav-item">
                  <Link
                    to="/vouchers/issuebalance"
                    className={
                      pathname === "/vouchers/issuebalance"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Issue Balance </p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="#" className="nav-link">
              <i class="nav-icon fas fa-money-bill"></i>
                <p>
                  Sale
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/sale/makeinvoice"
                    className={
                      pathname === "/sale/makeinvoice"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Tax Invoice </p>
                  </Link>
                </li>
              
                <li className="nav-item">
                  <Link
                    to="/sale/taxinvoice"
                    className={
                      pathname === "/sale/quotation"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Quotation </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/sale/invoicedetails"
                    className={
                      pathname === "/sale/invoicedetails"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Invoice Details</p>
                  </Link>
                </li>
              </ul>
            </li>

            {/* <li className="nav-item">
              <Link to="#" className="nav-link">
                <i class="nav-icon fas fa-copy"></i>
                <p>
                  Vendors
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/vendors/newvendor"
                    className={
                      pathname === "/vendors/newvendor"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>New vendor </p>
                  </Link>
                </li>
              </ul>
            </li> */}

            {/* <li className="nav-item">
              <Link to="#" className="nav-link">
                <i class="nav-icon fas fa-user"></i>
                <p>
                  Customers
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/customers/newcustomer"
                    className={
                      pathname === "/customers/newcustomer"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>New Customer </p>
                  </Link>
                </li>
              </ul>
            </li> */}
            <li className="nav-item">
              <Link to="#" className="nav-link">
                <i class="nav-icon fas fa-car"></i>
                <p>
                  Transport
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
              <li className="nav-item">
                  <Link
                    to="/transports/newcontract"
                    className={
                      pathname === "/transports/newcontract"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Contracts</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/transports/newvehicle"
                    className={
                      pathname === "/transports/newvehicle"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Vehicle management</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/transports/booking/scheduling"
                    className={
                      pathname === "/transports/booking/scheduling"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Booking & Scheduling</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/transports/booking/maintainence"
                    className={
                      pathname === "/transports/booking/maintainence"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Maintenance & Repair</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/transports/maintenance"
                    className={
                      pathname === "/transports/maintenance"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Compliance & Documentation</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="#" className="nav-link">
                <i class="nav-icon fas fa-money-bill"></i>
                <p>
                  Payroll
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                {/* <li className="nav-item">
                  <Link
                    to="/payroll/newstaff"
                    className={
                      pathname === "/payroll/newstaff"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>New Staff </p>
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    to="/payroll/labour"
                    className={
                      pathname === "/payroll/labour"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Add New Labour </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/payroll/newstaff"
                    className={
                      pathname === "/payroll/newstaff"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Add New Staff </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/payroll/labourattendance"
                    className={
                      pathname === "/payroll/labourattendance"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Labour Salary </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/payroll/staffsalary"
                    className={
                      pathname === "/payroll/staffsalary"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Staff Salary </p>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    to="/payroll/rules"
                    className={
                      pathname === "/payroll/rules"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Salary Rules </p>
                  </Link>
                </li> 
                {/* <li className="nav-item">
                  <Link
                    to="/payroll/advance"
                    className={
                      pathname === "/payroll/advance"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Deductions/Advance </p>
                  </Link>
                </li> */}
                {/* <li className="nav-item">
                  <Link
                    to="/payroll/staffsalary"
                    className={
                      pathname === "/payroll/staffsalary"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Make Salary </p>
                  </Link>
                </li> */}
              </ul>
            </li>
            <li className="nav-item">
              <Link to="#" className="nav-link">
              <i class="nav-icon fas fa-chart-pie"></i>
                <p>
                  Reports
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
              <li className="nav-item">
                  <Link
                    to="/reports/salereport"
                    className={
                      pathname === "/reports/salereport"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon " />
                    <p>Sale Report </p>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    to="/reports/journal"
                    className={
                      pathname === "/reports/journal"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon " />
                    <p>Journal Report </p>
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    to="/reports/expenses"
                    className={
                      pathname === "/reports/expenses"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon " />
                    <p>Expenses </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/reports/income"
                    className={
                      pathname === "/reports/income"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon " />
                    <p>Income </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/reports/combined"
                    className={
                      pathname === "/reports/combined"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon " />
                    <p>Combined Details </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link

                    to="/reports/acquitance"
                    className={
                      pathname === "/reports/acquitance"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon " />
                    <p>Acquitance Roll </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link

                    to="/reports/acquitancesummary"
                    className={
                      pathname === "/reports/acquitancesummary"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon " />
                    <p>Acquitance Summary </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link

                    to="/reports/mainaccount"
                    className={
                      pathname === "/reports/mainaccount"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon " />
                    <p>Main Account </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/reports/mileage"
                    className={
                      pathname === "/reports/mileage"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon " />
                    <p>Vehicle Mileage </p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="#" className="nav-link">
                <i class="nav-icon fas fa-users"></i>
                <p>
                  Users
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/users/newuser"
                    className={
                      pathname === "/users/newuser"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>New User </p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="#" className="nav-link">
              <i class="nav-icon fas fa-gears"></i>
                <p>
                  Settings
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/settings/newtax"
                    className={
                      pathname === "/settings/newtax"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon text-danger" />
                    <p>New Tax </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/settings/unit"
                    className={
                      pathname === "/settings/unit"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>New Unit </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/settings/qrcode"
                    className={
                      pathname === "/settings/qrcode"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>QR Code </p>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
}
