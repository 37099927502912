import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { format } from "date-fns";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";

import Vendor_form from "../../components/forms/Vendor_form";


export default function Newvendor() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out.Please logged in again");
    localStorage.clear();
    // history.push('/');
    window.location.href = "/";
  }
  const today = new Date();
  const month = today.getMonth()+1;
const year = today.getFullYear();
const date = today. getDate();
const currentDate = year + "/" + month + "/" + date;
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [lastID, setLastID] = useState(0);
  const [errors, setErrors] = useState({});
  const [Selectdatagroup, setSelectDatagroup] = useState([]);
  const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
  const [heads, setHeads] = useState([]);
  const [formdata, setFormData] = useState({
    company_name: "",
    customer_name: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });
  const handleChange1 = (e) => {
   // console.log(e.target.value);
    setFormData({ ...formdata, [e.target.id]: e.target.value });
     
    fetchData_singlePara("subgroups", setSelectDatasubgroup,"group_name",e.target.value, "id");
   // console.log(setSelectDatagroup);
  };
  const getheads = (e) => {
    // console.log(e.target.value);
     setFormData({ ...formdata, [e.target.id]: e.target.value });
      
//     fetchData_singlePara("heads", setHeads,"subgroup","Current Liabilities", "id");
    // console.log(setSelectDatagroup);
   };

  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };
  //handle add record
  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("vendors", setData, "id");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   
   let last
    const formdata1 = e.target;
    axios
      .post(
        "/insertdata/vendors",
        {
          group_name: "-",
          subgroup_name: "-",
          head_name: formdata1.head.value,
          company_name: formdata1.company_name.value,
          customer_name: formdata1.cname.value,
          contact: formdata1.contact.value,
          street1: formdata1.street1.value,
          street2: formdata1.street2.value,
          city: formdata1.city.value,
          pincode: formdata1.pin.value,
          gst: formdata1.gst.value,
          ob: formdata1.ob.value,
        },
        { headers }
      )
      .then((res) => {
        fetchData("vendors", setData, "id");
        //console.log(res);
        setLastID(res.data.id);
        alert(res.data.id);
        alert(data[0].id+1);
        const newRecord = res;
        handleAddRecord(newRecord);
        setFormData({
          company_name: "",
          cname: "",
          contact: "",
          street1: "",
          street2: "",
          city: "",
          pincode: "",
          gst: "",
          ob: "",
        });
        //navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
      });
      axios
      .post(
        "/insertdata/subheads",
        {
          vendor_id:data[0].id+1,
          head_name: formdata1.head.value,
          subhead_name: formdata1.company_name.value,
         
        },
        { headers }
      )
      .then((res) => {
        setFormData({
        
        });
        //navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
      });
      axios
      .post(
        "/insertdata/expenses",
        {
          purchase_id: data[0].id+1,
          date: currentDate,
          invdte: currentDate,
          userid: localStorage.getItem('uname'),
          vendor: formdata1.company_name.value,
          head: formdata1.head.value,
          subhead: formdata1.company_name.value,
          group_name: "Liabilities",
          subgroup_name: "Current Liabilities",
          ref: "",
          payment_mode: "credit",
          bank: "NA",
          credit: formdata1.ob.value,
          tax: "0",
          disc: "0",
          debit: "0",
          balance:formdata1.ob.value ,
          remark: "opening balance",
        },
        { headers }
      )
      .then((res) => {
        
      })
      .catch((err) => {
        console.log(err.message);
      });
  
  };

  //Table data record
  // const [data, setData] = useState([]);
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);

  const handleDelete = async (id) => {
    deleteRecord("vendors", id, "id");
    deleteRecord("subheads", id, "vendor_id");
    deleteRecord("expenses", id, "purchase_id");
    fetchData("vendors", setData, "id");
  };
  const [head, setHead] = useState([]);
  useEffect(() => {
    //fetchData("groups", setSelectDatagroup,"id");
    fetchData("heads", setHeads,"id");
   
    
  }, []);
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Ledger Information" paglink="Ledger Information" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">Add New Ledger Head</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                 <Vendor_form />

                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="vendors"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                    setData={setData} // Pass setData to allow updates
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
