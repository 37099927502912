import React from 'react';
import QRCodeGenerator from '../components/Qrcodegenerator';

const Qrcode = () => {
  const url1 = 'https://www.instagram.com/jannaatclubofficial_?igsh=cXdvd2RvMXd6MjZp';
  const url2 = 'https://www.facebook.com/jannaatclub?mibextid=LQQJ4d';
  const url3 = 'https://www.sharmasweet.com/';
  const url4 = 'https://www.google.com/search?client=ms-android-oppo-rev1&sca_esv=2abbba4047a5d337&sca_upv=1&sxsrf=ADLYWILGwrcakEfgo4R4Pp4Gdqg7TvaO7A:1727076531231&q=jannaat+club+pattaya+reviews&uds=ADvngMinzf45rJO9g2WPHGp-2jZc-77hGLmDPwJSTORJTJNnmRDdMf1t0GLQkVPV1tTH2LcpX1dt7Qt-aTz1AGmTNKnf-UqG4KrwYnbm2ceWon0ickFTUREXqevaCqMRO8PqGLILrBVeRUoUzSEyMdBRDYD7hQjJihkMHd5KrtRuAkLXLw-FbL4DWobi0Gcc67rJ5yHbFIHxAJZyFWEdh8-etXA587a82__a1vc2Aki3eARLyE7_ygJiVr1x5CkN_7q6yO1rksHaorbS_u1wL4YhCJDPXC8IxIiQMelGFMzHzOCMDOCHHqlK1392v9TAL_40QUwSeRon1K5svBYnvHTuyxwvvSJzcwGIn9mPVkEPt3i1SGAbXyaDPMheapji2uTYA3xq0jgLEGGVSOCdiotbHhERUxzbGmMex6NFS7GSoyF6zG2rxBCF30-OncA_Lfuw1g7Cn7oQ&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7oY-vPtMIhjT-jku3gdfB36-hx7Xm44qTiLW75rf7BkNqtqPFfyiCEKXuZKmsNH7b92aA8-ymsQCCbrVmB1T3nQXrfMeWeaQN_Ssp9FoIJi46GMkSw%3D%3D&sa=X&ved=2ahUKEwid-pK5xdiIAxXZRmcHHbe7IR4Qk8gLegQIIxAB&ictx=1';

  return (
    <div className='container'>
      <h1>QR Code Generator Instagram-Jannaat</h1>
  
<br/><br/><br/>
      <QRCodeGenerator url={url1} />
      <br/><br/><br/>
      <h1>QR Code Generator Facebook -Jannaat</h1>
      <QRCodeGenerator url={url2} /><br/><br/><br/>
      <h1>Google review Jannaat</h1>
      <QRCodeGenerator url={url4} />
      <h1>QR Code Generator Sharma Sweet Shop</h1>
      <QRCodeGenerator url={url3} />
    </div>
  );
};

export default Qrcode;
