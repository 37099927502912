import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { checkToken } from "../../services/checkToken";
import { fetchData_singlePara } from "../../util/fetchDataby_singlePara";

export default function Vendor_form() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const currentDate = year + "/" + month + "/" + date;
    const headers = { Authorization: authheader().access_token };
    const [data, setData] = useState([]);
    const [lastID, setLastID] = useState(0);
    const [errors, setErrors] = useState({});
    const [Selectdatagroup, setSelectDatagroup] = useState([]);
    const [GroupName, setGroupName] = useState([]);
    const [Selectdatasubgroup, setSelectDatasubgroup] = useState([]);
    const [heads, setHeads] = useState([]);
    const [formdata, setFormData] = useState({
        company_name: "",
        customer_name: "",
        contact: "",
        street1: "",
        street2: "",
        city: "",
        pincode: "",
        gst: "",
        ob: "",
    });

      const handleSubmit = async (e) => {
        e.preventDefault();
        const formdata1 = e.target;
    
        try {
            // Insert vendor data
            const vendorResponse = await axios.post(
                "/insertdata/vendors",
                {
                    group_name: "-",
                    subgroup_name: "-",
                    head_name: formdata1.head.value,
                    company_name: formdata1.company_name.value,
                    customer_name: formdata1.cname.value,
                    contact: formdata1.contact.value,
                    street1: formdata1.street1.value,
                    street2: formdata1.street2.value,
                    city: formdata1.city.value,
                    pincode: formdata1.pin.value,
                    gst: formdata1.gst.value,
                    ob: formdata1.ob.value,
                },
                { headers }
            );
    
            // Add new vendor directly to the table
            setData((prevData) => [...prevData, vendorResponse.data]);
    
            // Reset form fields
            setFormData({
                company_name: "",
                cname: "",
                contact: "",
                street1: "",
                street2: "",
                city: "",
                pincode: "",
                gst: "",
                ob: "",
            });
    
            // Perform any additional operations (e.g., adding subhead or expense data)
            if (vendorResponse.data?.id) {
                await axios.post(
                    "/insertdata/subheads",
                    {
                        vendor_id: vendorResponse.data.id,
                        head_name: formdata1.head.value,
                        subhead_name: formdata1.company_name.value,
                    },
                    { headers }
                );
    
                await axios.post(
                    "/insertdata/expenses",
                    {
                        purchase_id: vendorResponse.data.id,
                        date: currentDate,
                        invdte: currentDate,
                        userid: localStorage.getItem("uname"),
                        vendor: formdata1.company_name.value,
                        head: formdata1.head.value,
                        subhead: formdata1.company_name.value,
                        group_name: "Liabilities",
                        subgroup_name: "Current Liabilities",
                        ref: "",
                        payment_mode: "credit",
                        bank: "NA",
                        credit: formdata1.ob.value,
                        tax: "0",
                        disc: "0",
                        debit: "0",
                        balance: formdata1.ob.value,
                        remark: "opening balance",
                    },
                    { headers }
                );
            }
        } catch (err) {
            console.error(err.message);
        }
    };
    
       // Handle change for form fields
       const handleChange = (e) => {
        const { id, value } = e.target;
        
        // Update the form data for the changed field
        setFormData(prevData => ({
            ...prevData,
            [id]: value,
        }));

        // If the head field is changed, fetch the corresponding group name
        if (id === "head") {
            fetchData_singlePara("vendors", setFormData, "head_name", value, "id")
                .then(response => {
                    // Assuming the response is the group name you want
                    if (response && response.group_name) {
                        setFormData(prevData => ({
                            ...prevData,
                            groupName: response.group_name, // Set the fetched group name
                        }));
                    }
                })
                .catch(error => {
                    console.error("Error fetching group name:", error);
                });
        }
    };
    const handleAddRecord = (newRecord) => {
        setData([...data, newRecord]);
        fetchData("vendors", setData, "id");
      };
    useEffect(() => {
        fetchData("groups", setSelectDatagroup, "id");
        fetchData("heads", setHeads, "id");


    }, []);
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="card-body">
                    <div className="row">
                        {/* <div className="col-2">
                        <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Under Group</label>
                        <select 
                        className="form-control select2"
                        id="group"
                        onChange={(e) => handleChange1(e)}
                        value={formdata.group}
                        >
                          
                        <option selected="NA">--Select--</option>
                        {Selectdatagroup &&
                          Selectdatagroup.map((seldata, index) => {
                            return (
                              <option value={seldata.brand_name}>{seldata.brand_name}</option>
                            );
                          })}
                  </select>
                      </div>
                        </div>
                        <div className="col-2">
                        <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Under Sub Group</label>
                        <select 
                        className="form-control select2"
                        id="subgroup"
                        onChange={(e) => getheads(e)}
                        value={heads.brandname}
                        >
                          
                        <option selected="NA">--Select--</option>
                        {Selectdatasubgroup &&
                          Selectdatasubgroup.map((seldata, index) => {
                            return (
                              <option value={seldata.subgroup_name}>{seldata.subgroup_name}</option>
                            );
                          })}
                  </select>
                      </div>
                        </div> */}

                        <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Head</label>
                                <select
                                    className="form-control form-control-sm select2"
                                    id="head"
                                    onChange={(e) => handleChange(e)}
                                    value={heads.brand_name}
                                >

                                    <option selected="NA">--Select--</option>
                                    {heads &&
                                        heads.map((seldata, index) => {
                                            return (
                                                <option value={seldata.brand_name}>{seldata.brand_name}</option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="groupName">Group Name</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="groupName"
                                    value={formdata.groupName}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                    Ledger Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="company_name"
                                    onChange={(e) => handleChange(e)}
                                    value={formdata.company_name}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                    Ledger Nick Name
                                </label>
                                <input
                                    type="text"
                                    required
                                    className="form-control form-control-sm"
                                    id="cname"
                                    onChange={(e) => handleChange(e)}
                                    value={formdata.cname}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                    Contact No.
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="contact"
                                    onChange={(e) => handleChange(e)}
                                    value={formdata.contact}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Street 1</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="street1"
                                    onChange={(e) => handleChange(e)}
                                    value={formdata.street1}
                                />
                            </div>
                        </div>
                     
                    </div>
                    <div className="row">
                    <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Street 2</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="street2"
                                    onChange={(e) => handleChange(e)}
                                    value={formdata.street2}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">City</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="city"
                                    onChange={(e) => handleChange(e)}
                                    value={formdata.city}
                                />
                            </div>
                        </div>
                        <div className="col-1">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Pin Code</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="pin"
                                    onChange={(e) => handleChange(e)}
                                    value={formdata.pin}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                    GST(if Any)
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="gst"
                                    onChange={(e) => handleChange(e)}
                                    value={formdata.gst}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                    Opening Balance
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="ob"
                                    onChange={(e) => handleChange(e)}
                                    value={formdata.ob}
                                />
                            </div>
                        </div>
                        <div className="col-1">
                            <div className="form-group">
                                <button type="submit" class="btn mt-4 btn-dark float-right">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.card-body */}

            </form>
        </>
    )
}
