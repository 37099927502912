import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import authheader from "../../services/authheader";
import { deleteRecord } from "../../util/deleteRecord";
import { fetchData } from "../../util/fetchData";
import { checkToken } from "../../services/checkToken";
import FetchDataComponent from "../../components/tables/fetchTableData";
import Pagination from "../../components/tables/ShowTableData";
import Breadcums from "../../common/Breadcums";
import ModalTable from "../../components/ModalTable";
import ReactModal from 'react-modal';

import Vendor_form from "../../components/forms/Vendor_form";

export default function SubHeads() {
  try {
    checkToken();
  } catch (error) {
    alert("Session logged out. Please log in again.");
    localStorage.clear();
    window.location.href = "/";
  }
  const [itemsperpage, setitemsperpage] = useState([10]);
  const [columns, setColumns] = useState([]);
  const headers = { Authorization: authheader().access_token };
  const [data, setData] = useState([]);
  const [Selectdata, setSelectData] = useState([]);
  const [errors, setErrors] = useState({});
  const [formdata, setFormData] = useState({
    head_name: "",
    subhead: "",
  });

  // Separate modal states for Vendor, Supplier, and Contractor
  const [isVendorModalOpen, setIsVendorModalOpen] = useState(false);
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);
  const [isContractorModalOpen, setIsContractorModalOpen] = useState(false);

  // Open/Close handlers for each modal
  const openVendorModal = () => setIsVendorModalOpen(true);
  const closeVendorModal = () => setIsVendorModalOpen(false);

  const openSupplierModal = () => setIsSupplierModalOpen(true);
  const closeSupplierModal = () => setIsSupplierModalOpen(false);

  const openContractorModal = () => setIsContractorModalOpen(true);
  const closeContractorModal = () => setIsContractorModalOpen(false);

  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.id]: e.target.value });
  };

  const handleAddRecord = (newRecord) => {
    setData([...data, newRecord]);
    fetchData("products", setData);
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    const formdata1 = e.target;
    axios
      .post(
        "/insertdata/subheads",
        {
          head_name: formdata1.headname.value,
          subhead_name: formdata1.subhead.value,
        },
        { headers }
      )
      .then((res) => {
        fetchData("subheads", setData, "id");
        handleAddRecord(res.data);
        setFormData({
          brand_name: "",
          desc: "",
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
    setFormData({});
    setErrors({});
  };

  const handleDelete = async (id) => {
    deleteRecord("subheads", id, "id");
    fetchData("subheads", setData, "id");
  };

  useEffect(() => {
    fetchData("heads", setSelectData, "id");
    fetchData("subheads", setData, "id");
  }, []);


  const modalStyles = {
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'white',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    },
    overlay: {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
  };
  return (
    <>
  {/* Vendor Modal */}
  <ReactModal
        isOpen={isVendorModalOpen}
        onRequestClose={closeVendorModal}
        contentLabel="Add Vendor"
        style={modalStyles}
      >
        <h2>Add Vendor</h2>
        <Vendor_form />
        <button className="btn btn-danger mt-4 float-right" onClick={closeVendorModal}>Close</button>
      </ReactModal>

      {/* Supplier Modal */}
      <ReactModal
        isOpen={isSupplierModalOpen}
        onRequestClose={closeSupplierModal}
        contentLabel="Add Supplier"
        style={modalStyles}
      >
        <h2>Add Supplier</h2>
        {/* Placeholder for Supplier Form */}
        <button className="btn btn-danger mt-4 float-right" onClick={closeSupplierModal}>Close</button>
      </ReactModal>

      {/* Contractor Modal */}
      <ReactModal
        isOpen={isContractorModalOpen}
        onRequestClose={closeContractorModal}
        contentLabel="Add Contractor"
        style={modalStyles}
      >
        <h2>Add Contractor</h2>
        {/* Placeholder for Contractor Form */}
        <button className="btn btn-danger mt-4 float-right" onClick={closeContractorModal}>Close</button>
      </ReactModal>


      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <Breadcums heading="Master" />
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">

              <button type="button" className="btn btn-success mt-4" onClick={openVendorModal}>
                  Add Vendor
                </button>
                <button type="button" className="btn btn-danger mt-4" onClick={openSupplierModal}>
                  Add Supplier
                </button>
                <button type="button" className="btn btn-info mt-4" onClick={openContractorModal}>
                  Add Contractor
                </button>
              </div>
            </div>
            <div className="row">
              {/* left column */}
              <div className="col-md-6">
                {/* general form elements */}
                <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">Sub Heads Creation</h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <form onSubmit={handleSubmit1}>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Under Head</label>
                            <select className="form-control select2"
                              id="headname"
                              onChange={(e) => handleChange(e)}
                              value={formdata.headname}
                            >
                              <option selected="NA">--Select--</option>
                              {Selectdata &&
                                Selectdata.map((seldata, index) => {
                                  return (
                                    <option value={seldata.brand_name}>{seldata.brand_name}/{seldata.subgroup}</option>
                                  );
                                })}
                            </select>

                          </div>
                        </div>  <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Sub Head </label>
                            <input
                              type="text"
                              className="form-control"
                              id="subhead"
                              onChange={(e) => handleChange(e)}
                              value={formdata.subhead}
                              placeholder="Enter Sub Group Name"
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                    {/* /.card-body */}
                    <div className="card-footer">
                      <div className="row">
                        <div className="col-12">
                          <button type="submit" class="btn btn-dark float-right">
                            Save
                          </button>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-6">
                {/* general form elements */}
                <div className="card card-dark">
                  <FetchDataComponent
                    tblname="subheads"
                    setData={setData}
                    setColumns={setColumns}
                    id="id"
                  />

                  {/* This component fetches the data and determines columns */}
                  <Pagination
                    data={data}
                    columns={columns}
                    handleDelete={handleDelete}
                    itemsPerPage={itemsperpage}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
              <div className="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">Ledger Creation</h3>
                  </div>
              <Vendor_form />
              </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </>
  );
}
