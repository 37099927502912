import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Pagination from "./Pagination";
import axios from "axios";
import authheader from "../../services/authheader";


function ShowTableData({ data, columns, handleDelete,setData }) {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const currentDate = year + "/" + month + "/" + date;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [editRow, setEditRow] = useState(null); // Store the row data for editing
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [formData, setFormData] = useState({
    company_name: "",
    cname: "",
    contact: "",
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    gst: "",
    ob: "",
  });

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Add other headers if needed, like authentication headers
  // };
  const headers = { Authorization: authheader().access_token };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  const handleEditClick = (row) => {
    setEditRow(row); // Set the row to be edited
    setShowModal(true); // Show the modal
  };


  const handleModalClose = () => {
    setShowModal(false); // Close the modal
    setEditRow(null); // Reset the row data
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    if (!editRow?.id) {
      console.error("Edit row ID is missing");
      alert("Edit id is missing");
      return;
    }
  
    const formdata1 = new FormData(e.target);
    try {
      const updatePayload = {};
      for (let [key, value] of formdata1.entries()) {
        updatePayload[key] = value;
      }
  
      // Make API call
      const vendorResponse = await axios.put(
        `/updatedata1/vendors/id/${editRow.id}`,
        updatePayload,
        { headers }
      );
  
      if (vendorResponse.status === 200) {
        console.log("Vendor updated successfully:", vendorResponse.data);
  
        // Update local data state to reflect the changes
        const updatedData = data.map((item) =>
          item.id === editRow.id ? { ...item, ...updatePayload } : item
        );
        setData(updatedData); // Update the state to reflect changes
  
        // Reset and close modal
        setFormData({
          company_name: "",
          cname: "",
          contact: "",
          street1: "",
          street2: "",
          city: "",
          pincode: "",
          gst: "",
          ob: "",
        });
        setShowModal(false);
      } else {
        console.error("Failed to update vendor data:", vendorResponse);
      }
    } catch (err) {
      console.error("Error updating vendor data:", err.message);
    }
  };
  
  


  return (
    <>
      <div className="card-body table-responsive p-0">
        <table className="table table-hover text-nowrap" id="tbldata">
          <thead>
            <tr>
              <th>#</th>
              {columns.map((column) => (
                <th key={column}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentData.map((row, index) => (
              <tr key={index}>
                <td>{startIndex + index + 1}</td>
                {columns.map((column) => (
                  <td key={column}>
                    {row[column] ||
                      (column === "Action" && (
                        <div className="btn-group btn-group-sm">
                          <Button
                            variant="success"
                            size="sm"
                            onClick={() => handleEditClick(row)}
                          >
                            <i className="fa fa-edit" />
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDelete(row.id)}
                          >
                            <i className="fa fa-trash" />
                          </Button>
                        </div>
                      ))}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        postsPerPage={itemsPerPage}
        totalPosts={data.length}
        paginate={paginate}
      />

      {/* Modal for Editing */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editRow && (
            <Form onSubmit={handleFormSubmit}>
              {columns.map((column) => {
                if (column === "Action") return null; // Skip 'Action' column

                return (
                  <Form.Group className="mb-3" key={column}>
                    <Form.Label>{column}</Form.Label>
                    <Form.Control
                      type="text"
                      name={column} // Bind name to column
                      defaultValue={editRow[column] || ""} // Populate with current row data
                      disabled={column === "id"} // Prevent editing the ID
                    />
                  </Form.Group>
                );
              })}
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            </Form>
          )}

        </Modal.Body>
      </Modal>
    </>
  );
}

export default ShowTableData;
